
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IProductListRequest, IProduct } from "@/types/product";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //product
  @Action("product/userGetList")
  public getProductList!: (request: IProductListRequest) => Promise<boolean>;

  @Getter("product/alertList")
  public alertProductList!: IProduct[];

  @Mutation("product/clear")
  public productClear!: () => void;

  //
  // variables
  //
  public headers = [
    { text: "商品名", value: "name", sortable: false },
    { text: "品番", value: "code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "顧客名", value: "customer_name", sortable: false },
    { text: "メーカ", value: "maker_name", sortable: false },
    { text: "総ロット数", value: "total_lot", sortable: false, align: "end" },
    {
      text: "総在庫",
      value: "stockage_quantity",
      sortable: false,
      align: "end"
    },
    { text: "受発注", value: "ordering", sortable: false }
  ];

  //
  // methods
  //
  private async created() {
    this.productClear();

    await this.getProductList({ per_page: 0 });
  }

  // 行をクリックした際の挙動
  public rowClick(product: IProduct) {
    this.$router.push(`/user/inventory/${product.id}/state`);
  }
}
